<template>
<v-card v-if="isShow" width="400" height="200">
  <v-container fluid>
    <div class="text-subtitle-2 pl-3">项目删除操作不可恢复！请谨慎操作</div>
    <v-radio-group v-model="removeMode">
      <v-radio  :value="0">
        <template v-slot:label>
          <div class="text-caption">不删除项目下的任务</div>
        </template>
      </v-radio>
      <v-radio :value="1">
        <template v-slot:label>
          <div class="text-caption">同时删除项目下所有任务</div>
        </template>
      </v-radio>
      <v-radio :value="2">
        <template v-slot:label>
          <div class="text-caption">同时删除我负责的，我参与的，我派发的任务</div>
        </template>
      </v-radio>
    </v-radio-group>
    <div class="d-flex mt-n3">
      <v-spacer></v-spacer>
      <v-btn class="mx-1" @click="cancel" small>取消</v-btn>
      <v-btn class="mx-1" @click="agree" small color="primary">提交</v-btn>
    </div>
  </v-container>
</v-card>
</template>
<script>
export default {
  name: 'RemoveItemDlg',
  data() {
    return {
      isShow: false,
      resolve: null,
      reject: null,
      removeMode: 0,
    }
  },
  methods: {
    open() {
      this.isShow = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      })
    },
    agree () {
      this.resolve({mode: this.removeMode});
      this.isShow = false;
    },
    cancel () {
      this.resolve(false);
      this.isShow = false;
    }
  }
}
</script>